.title {
  font-size: 56px;
  text-transform: uppercase;
  text-align: center;
  color: #fdbf4c;
  background: linear-gradient(90deg, #fed083, #ffae27);
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 1;
  -webkit-text-stroke: 3px #582b02;
  text-stroke: 3px #582b02;
  font-weight:400;
  font-family: 'Riffic', sans-serif;
}
