
.invite-btn {
    position: relative;
    display: flex;
    align-items: center;
    gap: 9px;
    color: rgba(88, 43, 2, 1);
    padding: 9px;
    width: 100%;
    height:40px;
    background: linear-gradient(
      181deg,
      rgba(254, 208, 131, 1),
      rgba(255, 174, 39, 1)
    );
    box-shadow: 0 3px rgba(0, 0, 0, 0.3), inset 0 -3px rgba(255, 255, 255, 0.1);
    justify-content: center;
    border-radius: 10px;

    transition: transform 0.1s ease-in-out;
  
    &:after {
      content: "";
  
      position: absolute;
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border: 3px solid transparent;
      border-radius: 13px;
      background: linear-gradient(to bottom, #b48d58 0%, #57310e 100%) border-box;
      -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor; /*5'*/
      mask-composite: exclude;
    }

    &:active {
        transform: translateY(1px) scale(0.98);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

.sharp-texture {
    border: 1.5px solid #582B02 !important;
    box-shadow: 0px -4px 0px 0px #0000001A inset,0px 5px 0px 0px #FFFFFF33 inset,0px 15px 5px 0px #FED08373 inset,0px 3px 0px 0px #00000066 !important;
    background-image: url("./assets/button-texture.png") !important;
}

.invite-btn > img {
    height:35px;
}