.roulette-wrapper{
    position:relative;
    display:flex;
    justify-content:center;
    width:90%;
    margin:0 auto;
    overflow:hidden;
    border-radius:25px;
  }
  
  .roulette-wrapper .selector{
    width:3px;
    background:grey;
    left:50%;
    height:100%;
    transform:translate(-50%,0%);
    position:absolute;
    z-index:2;
  }
  
  .roulette-wrapper .wheel{
    display:flex;
    background: #46B2FF;
    height:100px;
    align-items:center;
    box-shadow: inset 0 0 4px rgb(88 43 2);
    border: 1px solid #582b02;
  }
  
  .roulette-wrapper .wheel .row{
    display:flex;
  }

  .card__text {
    color:white;
  }
  
  .roulette-wrapper .wheel .row .card{
    height:75px;
    width:75px;
    margin:3px;
    border-radius:8px;
    border-bottom:3px solid rgba(0,0,0,0.2);
    display:flex;
    align-items:center;
    justify-content:center;
    /* color:white; */
    font-size:1.4em;
    /* background-image: url("./assets/toncoin.png");
    background-size: contain; */
    
  }

  
  
  .card.red{
    background:#F95146;
  }

  .card.blue {
    background: #0084C6;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:2px;
    flex-direction: column;
  }

  .card.blue img {
    width: 25px;
  }
  
  .card.black{
    background:#2D3035;
  }
  
  .card.green{
    background:#00C74D;
  }

.spinning {
    
}