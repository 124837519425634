.history-tabs {
  max-width: 450px;
  margin: 0 auto;
}

.history-tabs__btns {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.history-tabs__content {
  display: none;
  margin-top: 20px;

}

.history-tabs__content.active {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-gap: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tasks-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top:40%;
}

