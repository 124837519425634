.rank-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:7px;
    justify-content: center;
    background: linear-gradient(180deg, #FED083 49.5%, #FFAE27 100%);
    border-radius: 24px;
    width:30%;
    height: 5%;
    border: 2px solid #DB8412;

    position: absolute;
    bottom: 105px;
    z-index: 2;
    
}

.rank-item__place {
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    color: #582B02;
    font-size: 16px;

}