.swap-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    background-size: cover;
    font-family: 'Riffic', sans-serif;
    gap: 20px;
    margin-top:35px;
}

.swap-page__swap {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:30px;
    box-shadow: 
    0 12px 1px 0 rgba(0, 0, 0, 0.25) inset, 0 -12px 1px 0 rgba(0, 0, 0, 0.25) inset;

    padding: 30px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid black;
    width:100%;
}

.swap-page__swap-title {
    font-size: 36px;
    text-shadow: 
  0 3px 0 rgba(0, 0, 0, 0.4);
    color: white;
}

.swap {
    background-image: url("./assets/Wood-texture.png");
    background-size: contain;
}

.swap-page__swap-input{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.4);
    gap: 10px;
    width: 100%;
}

.sell {
    background-color: #4b2c20;
    box-shadow: 
  inset 0 -1px 0 0 rgba(0, 0, 0, 0.25),
  inset 0 2px 0 0 rgba(252, 207, 133, 0.30);
  color: white;
}

.buy {
    background: linear-gradient(0deg, #FFAE27,#FED083);
    box-shadow: 
  inset 0 4px 0 0 rgba(255, 233, 195, 0.45),
  inset 0 5px 0 0 rgba(255, 255, 255, 0.20),
  inset 0 -4px 0 0 rgba(0, 0, 0, 0.10);
}

.swap-page__swap-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width:100%;
}
.swap-page__swap-container img {
    width:36px;
}

.swap-page__swap-input__block {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:5px;
    padding: 5px 10px;
    border-radius: 25px;
}

input {
    width:100%;
    border: none;
    outline: none;
    text-align:right;

}


.buy-block {
    background-color: #4b2c20;
}

.sell-block {
    background: linear-gradient(0deg, #FFAE27,#FED083);
    color: #4b2c20;
}

.buy-block > input {
    background-color: transparent;
    background-image: none;
    background: linear-gradient(0deg, #FFAE27, #FED083);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  caret-color: #FEBB4C;
}

.sell-block > input {
    background-color: transparent;
    background-image: none;
    color: #4b2c20;
    caret-color: #4b2c20;
}

.sell-block > input::placeholder{
    color: #4b2c20;
}

.buy-block > span {
    background: linear-gradient(0deg, #FFAE27, #FED083);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }

.swap-page__swap-input__balance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
}

.swap-page__swap-input__descr {
    font-size: 12px;
}

img:active {
    transform: scale(1.1);
    transition: transform 0.1s ease-in-out;
}

.swap-page__swap-input__block > img {
    width: 30px;
    height:30px;
}

.swap-page__history {
    display:flex;
    flex-direction: column;
    gap: 16px;
    width:100%;
}

.transaction__history-loader {
    display:flex;
    align-items: center;
    justify-content: center;
}

.coin-img {
    width: 30px;
    height: 30px;
}