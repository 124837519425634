.claim-ton-popup {
    z-index: 3;
    background-color: white;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: calc(100% - 32px);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease-in-out;
    border-radius:30px;
    background-image: url("./assets/Wood-texture.png");
    background-size: cover;
    background-repeat: no-repeat;

    border: 2px solid #582B02;
    box-shadow:  inset 0px 3px 0px 0px #00000066;
}
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.claim-ton-popup .gift {
    width: 105%;
    position: absolute;
    top: calc(var(--tg-viewport-stable-height)* -0.1);
}

.claim-ton-popup-open {
    bottom: calc(var(--tg-viewport-stable-height)* -0.47);
}
  


.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index:3;

  }
  
  .close-icon {
    width: 40px;
    height: 40px;
    fill: #fff;
    stroke: #333;
    stroke-width: 2;
  }


.claim-ton-popup__roulette {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: calc(var(--tg-viewport-stable-height)* 0.12);
    align-items: center;
    transform: scale(0);
    animation: appear 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.5) forwards;
}

  
@keyframes appear {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

.claim-ton-popup__btn {
    background-color: #0098EA;
    color: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    padding: 8px 16px 8px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 40px;

    &:active {
        transform: translateY(1px) scale(0.98);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

.success-icon {
  position: absolute;
  top: -160px;
  width: 360px;
}

.claim-ton-popup__text-share {
  color: white;
  font-family: 'Riffic', sans-serif;
  text-align: center;
  padding: 10px;
  text-shadow: -1px 0 #582b02, 0 1px #582b02, 1px 0 #582b02, 0 -1px #582b02;
}

.claim-ton-popup__share-btn {
  position: 'absolute';
  top: calc(var(--tg-viewport-stable-height)* 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top:calc(var(--tg-viewport-stable-height)* 0.15)
}

  
  
  
