th,td {
    color: white;
    font-family: "Riffic", sans-serif;
}

th {
    font-size: 12px;
    font-weight: 700;
    padding: 8px;
}

td {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding: 5px 10px !important;
}

td > img {
    width:22px;
    height: 22px;
}
tbody > tr {
    border-bottom: 1px solid white;
}

tbody > tr:last-child {
    border-bottom: none;
}

table {
    /* border-collapse: separate;
    border-spacing: 10px 10px; */
    width: 100%;
}

.history-table__winner {
    /* font-size:12px; */
    font-weight: 700;
    
}
.history-table__winner > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 80px;
}

.history-table__prize {
    font-size:12px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    gap: 8px
}

.history-table__prize > span {
    align-self: center;
    text-shadow: 0px 4px 4px #00000040;

}