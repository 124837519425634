.swiper-button-prev,
.swiper-button-next {
  width: 37px;
  height: 37px;
  background-size: contain;
  background-repeat: no-repeat;
  &::after {
    font-size: 0;
  }
}

.swiper-button-prev {
  background-image: url("./assets/images/left.svg");
}

.swiper-button-next {
  background-image: url("./assets/images/right.svg");
}

.league-slider__title {
  font-size: 24px;
  display: block;
  text-align: center;
  font-weight: 1000;
}

.league-slider__text {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  margin: auto;
  width: 210px;
  text-align: center;
  margin: 12px auto 28px;
}

.league-slider__img {
  display: block;
  margin: auto;
}
