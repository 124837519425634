.no-data {
    display:flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    flex-direction: column;
    
}

.no-data__title {
    font-weight:700;
    font-family: "Riffic", sans-serif;
    font-size: 20px;
}

.no-data__text {
    font-weight:400;
    font-family: "Riffic", sans-serif;
    align-self:center;
    text-align:center;
}