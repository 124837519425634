.history-item {
  padding: 13px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: #fff;
  background-image: url(./assets//historyItem-bg.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.history-item__container {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.history-item__img-telegram {
  max-width: 28px;
}

.history-item__block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Montserrat", sans-serif;
}

.history-item__price {
  font-weight: 700;
}

.history-item__btn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(88, 43, 2, 1);
  padding: 6px;
  width: 100px;
  background: linear-gradient(
    181deg,
    rgba(254, 208, 131, 1),
    rgba(255, 174, 39, 1)
  );
  box-shadow: 0 3px rgba(0, 0, 0, 0.3), inset 0 -3px rgba(255, 255, 255, 0.1);
  justify-content: center;
  border-radius: 10px;

  &:after {
    content: "";

    position: absolute;
    left: -3px;
    top: -3px;
    right: -3px;
    bottom: -3px;
    border: 3px solid transparent;
    border-radius: 13px;
    background: linear-gradient(to bottom, #b48d58 0%, #57310e 100%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude;
  }

  &:active {
    transform: translateY(1px) scale(0.98);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
}

.history-item__img-arrow {
  max-width: 23px;
}

.history-item__price-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap:5px;
  justify-content: center;
  align-items: center;
}

.history-item__price-block > img {
  width:20px;
}
