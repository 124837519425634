.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1;
    animation: fade-in 0.5s ease-in-out;
  }
  
  .modal-content {
        top: -1000px;
        background: linear-gradient(180deg, #FED083 49.5%, #FFAE27 100%);
        padding: 20px;
        border-radius: 24px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 90%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: top 0.5s ease-in-out;
        z-index:2;

        box-shadow: 
        0px -5px 0px 0px #0000001A inset,
        0px 5px 0px 1px #FFFFFF33 inset,
        0px 3px 0px 0px #00000066;
  }
  
  .modal-content.open {
    top:50px;
  }
  
  .close-button {
    position: absolute;
    top: -38px;
    right: -1px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .close-button img {
    width: 30px;
    height: 30px;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
