.bar-king-table {
    width: 100%;
    height: 370px;
    background-image: url("./assets/table.png");
    background-size: 100%;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: "Riffic", sans-serif;
    color: white;
}

.bar-king-table__bank-number {
    display:flex;
    flex-direction: row;
    gap:5px;
    justify-content: center;
    align-items: center;
}

.bar-king-table__bank-title {
    font-size: 12px;
}

.bar-king-table__bank-number > span {
    font-size: 24px;
    text-shadow: 0px 4px 4px 0px #00000040;

}

.bar-king-table__bank-number > img {
    width:30px;
    height:30px;
}

.bar-king-table__time-title {
    font-size: 12px;
}

.bar-king-table__time-number {
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.bar-king-table__time-number > span:nth-child(odd) {
    display: block;
    padding: 5px;
    background: #00000040;
    border: 1px solid #FFFFFFD9;
    border-radius:5px;
}

.bar-king-table__bet-title {
    font-size: 12px;
}

.bar-king-table__empty {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.bar-king-table__empty > img {
    width: 130px;
}

.bar-king-table__empty-text {
    display: flex;
    flex-direction: column;
    gap:2px;
    align-items: center;
    justify-content: center;
}

.gradient-text {
    font-size: 20px;
    font-weight: 1000;
    background: linear-gradient(180deg, #FED083 49.5%, #FFAE27 100%);
    -webkit-background-clip: text; /* Clips the background to the text */
    -webkit-text-fill-color: transparent; /* Makes the text background visible */
    -webkit-text-stroke: 1px #582B02; /* Applies the border (stroke) around the text */
    text-transform: uppercase;
}


.bar-king-table_winner-img {
    width:77px;
    height:77px;
    border-radius: 91px;
}