.game-popup {
    z-index: 3;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: calc(100% - 32px);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("./assets/popup-img__bg.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  
  .game-popup-open {
    bottom: -20px;
  }
  
  .game-popup-close {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
  }
 
.game-popup__title {
    font-size: 36px;
    color: #582B02;
    font-weight:400;
    align-self: center;
}



.game-popup__rules-text{
    font-family: "Riffic", sans-serif;
    font-weight: 700;
    font-size: 32px;
    background: linear-gradient(180deg, #FED083 49.5%, #FFAE27 100%);
    -webkit-text-stroke: 2px #582B02;
    -webkit-background-clip: text;
    color: transparent;
}

.game-popup-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center
  }

.game-popup__slider {
    width:100%;
}

.game-popup__start-game-button {
    font-family: "Riffic", sans-serif;
    font-weight: 700;
    margin-top:40px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 9px;
    color: rgba(88, 43, 2, 1);
    padding: 9px;
    width: 250px;
    background: linear-gradient(
      181deg,
      rgba(254, 208, 131, 1),
      rgba(255, 174, 39, 1)
    );
    box-shadow: 0 3px rgba(0, 0, 0, 0.3), inset 0 -3px rgba(255, 255, 255, 0.1);
    justify-content: center;
    border-radius: 10px;

    transition: transform 0.1s ease-in-out;
  
    &:after {
      content: "";
  
      position: absolute;
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border: 3px solid transparent;
      border-radius: 13px;
      background: linear-gradient(to bottom, #b48d58 0%, #57310e 100%) border-box;
      -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor; /*5'*/
      mask-composite: exclude;
    }

    &:active {
        transform: translateY(1px) scale(0.98);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
  