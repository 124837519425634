.tavern {
    background-image: url("./assets/tavern-img_2_game.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.tavern__profile {
    margin-top:10px;
    padding: 0 10px;
}

.tavern__tables {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px; 
    /* margin-top:30%; */
}



.tavern__table {
   width:calc(var(--tg-viewport-stable-height) * 0.135);
   justify-self: center; 
}

.tavern__table img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
