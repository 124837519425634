.home {
  background-image: url("./assets//home-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home__total {
  margin-top: 20px;
  user-select: none;
}

.home__league-link {
  margin: 10px auto 0;
  user-select: none;
  z-index:2;
}

.home__shovel {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 0;
  outline: none;
  user-select: none;
}

.home__shovel:focus {
  outline: none;
}

.home__shovel:focus-visible {
  outline: none;
}

.gift-button {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  transition: transform 0.3s;

  &:active {
    transform: translate(-3px, -50%);;
  }
}

.gift-button img {
  width: 50px;
}

.bounty-button {
  position: absolute;
  right: -5px;
  top: 61%;
  transform: translateY(-50%);
  z-index: 3;
  transition: transform 0.3s;

  &:active {
    transform: translate(-3px, -50%);;
  }
}

.bounty-button img {
  width: 50px;
}

