.app-container {

  height: 100dvh;
  background-image: url("./assets/img/main.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Titan One", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  color: rgba(88, 43, 2, 1);
  overflow: hidden;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
}

input {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

#loader {
  animation: spinner 2s linear infinite;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
