.bar-king-game {
    background-image: url("./assets/background.png");
    background-size: cover;
}

.bar-king-game__content {
    display:flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    font-family: "Riffic", sans-serif;
    margin-top: 10px;
}

.bar-king-game__bet-history {
    align-self: start;
    font-size:14px;
    font-weight: 700;
    color: white;
}

.bar-king-table__bet-section {
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-family: "Riffic", sans-serif;
    margin-top:40px;
}

.bar-king-table__bet-input{
    background: white;
    border-radius: 24px;
    width:55%;
    display:flex;
    font-family: "Riffic", sans-serif;
    padding: 6px 15px;
    flex-direction: column;
    gap:5px;
    justify-content: center;
}

.bar-king-table__bet-input-section__input {
    display:flex;
    flex-direction: column;
}

.bar-king-table__bet-input__balance {
    display:flex;
    justify-content: space-between;
    font-size: 12px;
}

.bar-king-table__bet-input__input {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.bar-king-table__bet-input__input > img {
    height: 30px;
    width: 30px;
}

.bar-king-table__bet-input__input > input {
    color: #582B02;
}

.bar-king-table__bet-button {
    border-radius:24px;
    width:45%;
}

.bar-king-table__bet-button {
    font-size: 16px;
    font-weight: 700;
    color: #582B02;
    background-image: url("./assets/button-texture.png");
    background-size: cover;
    padding: 14px 6px;
    text-align: center;
    border: 1.5px solid #582B02;
    box-shadow: 0px -4px 0px 0px #0000001A inset,0px 5px 0px 0px #FFFFFF33 inset,0px 15px 5px 0px #FED08373 inset,0px 3px 0px 0px #00000066;
    display:flex;
    justify-content: center;

}

.bar-king-table__bet-button > span {
    align-self: center;
}

.bar-king-table__bet-button:active {
    transform: translateY(1px) scale(0.98);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.bar-king-game__no-bet{
    font-size: 20px;
    font-weight: 1000;
    background: linear-gradient(180deg, #FED083 49.5%, #FFAE27 100%);
    -webkit-background-clip: text; /* Clips the background to the text */
    -webkit-text-fill-color: transparent; /* Makes the text background visible */
    -webkit-text-stroke: 1px #582B02; /* Applies the border (stroke) around the text */
    text-transform: uppercase;
    text-align: center;
}

.bar-king-game__loader {
    display: flex;
    justify-content: center;
}

.bet-coins {
    position: absolute;
    width:41px;
    z-index: 100;
    transition: transform 0.4s linear, left 0.4s linear, top 0.4s linear;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}