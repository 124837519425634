.bet-history-carousel__item {
    background-image: url("./assets/Wood-texture.png");
    background-size: cover;
    border: 2px solid #6D3902;
    box-shadow: 0px -5px 0px 0px #0000001A inset,0px 5px 0px 1px #FFFFFF33 inset,0px 3px 0px 0px #00000066;
    border-radius:24px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    gap:5px;
    color: white;
    width: 170px;
    height: 70px;
}

.bet-history-carousel__item > img {
    width:45px;
    height:45px;
    border-radius:91px;
    align-self: center;
}

.bet-history-carousel__item-info {
    display:flex;
    flex-direction: column;
    gap:5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Riffic",sans-serif;
}

.bet-history-carousel__item-info-bet > img {
    width: 22px;
    height: 22px;
}

.bet-history-carousel__item-info-bet {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.bet-history-carousel__slider {
    width: 100%;
    display:flex;
    height:70px;
}

.bet-history-carousel__animation {
    transition: all 0.5s ease-in-out;
}

/* .bet-history-carousel__slide {
    width:22%;
} */


