.bar-king-choice__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
    margin-top:20px;
    font-family: "Riffic", sans-serif;
}

.bar-king-choice__cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;   
    width:100%; 
}

.bar-king-choice__card {
    height: 184px;
    width: 100%;
    background-image: url("./assets/Wood-texture.png");
    background-size: cover;
    padding:10px;
    border: 2px solid #6D3902;
    border-radius:24px;
    box-shadow: 0px -5px 0px 0px #0000001A inset,0px 5px 0px 1px #FFFFFF33 inset,0px 3px 0px 0px #00000066;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;


}

.bar-king-choice__card-body> img {
    width:100px;
}

.bar-king-choice__card-body > img:active {
    transform: none;
}

.bar-king-choice__card:active{
    transform: scale(0.95);
}

.bar-king-choice__card-title {
    font-weight: 700;
    font-size:14px;
    color: white;
    text-transform: uppercase;
}

.bar-king-choice__card-description {
    font-weight: 700;
    font-size:14px;
    color: #582B02;
    text-align:center;
}

.bar-king-choice__history {
    font-weight: 700;
    font-size: 24px;
    color: #582B02;
    text-transform: uppercase;
}

.last_games__history-loader {
    display: flex;
    justify-content: center;
}

.bar-king-choice__card-header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.bar-king-choice__card-header > img {
    width: 25px;
}

.bar-king-choice__card-body {
    display:flex;
    flex-direction: column;
    gap:15px;
    align-items: center;
    justify-content: center;
}

.info-tooltip {
    z-index: 4;
    background-color:white !important;
    color: #582b02 !important;
    width:280px !important;
    font-family: 'Riffic', sans-serif !important;
}