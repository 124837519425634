
body{
    background-color: #000 !important;
}

/*ios fullscreen*/
.xxx-game-iframe-iphone-se{
    height: 226px !important;
}

.xxx-ios-fullscreen-message{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: none;
    background-color: rgba(0,0,0,0.5);
    
    z-index: 10000;    
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: none;
    touch-action: none;    
}

.xxx-ios-fullscreen-scroll{
    width: 100vw;
    height: 120vh;
    position: absolute;
    z-index: 10001;
    top: 0;
    left: 0;
    display: none;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: none;
    touch-action: none;     
}

.xxx-ios-fullscreen-swipe{
    width: 30%;
    height: 30%;
    
    background-image: url(../sprites/swipe.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    
    position: absolute;
    top: 50%;
    left: 50%;
    
    -ms-transform :  translate( -50%, -50%) !important;
    -webkit-transform :  translate( -50%, -50%) !important;
    transform :  translate( -50%, -50%) !important;    
    
    animation: xxx-animation-ios-swipe 1.5s ease infinite;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: none;
    touch-action: none;    
}


@keyframes xxx-animation-ios-swipe {
  0%, 100% {
    top: 40%;
  }
  50% {
    top: 60%;
  }
}