root { 
    display: block;
}

body{
    background-color: #000;
    background-repeat: repeat-x;
    background-position: top;   
}

*, *:before, *:after {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
     
input, input:before, input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}

::selection { background: transparent;color:inherit; }
::-moz-selection { background: transparent;color:inherit; }

#canvas{
    position: fixed;
 
}

canvas {
    -ms-touch-action: none;
}

.ani_hack{
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: transparent; /* mobile webkit */
}



/***************FONTS*******************/

.check-fonts{
        position: fixed;
        opacity:0;
}

.check-font-1{
        font-family: 'walibi0615bold';
}
                
                
@font-face {
    font-family: 'walibi0615bold';
    src: url('../fonts/walibi-holland-webfont.eot');
    src: url('../fonts/walibi-holland-webfontd41d.eot?#iefix') format('embedded-opentype'),
         url('../fonts/walibi-holland-webfont.woff2') format('woff2'),
         url('../fonts/walibi-holland-webfont.woff') format('woff'),
         url('../fonts/walibi-holland-webfont.ttf') format('truetype'),
         url('../fonts/walibi-holland-webfont.svg#walibi0615bold') format('svg');
    font-weight: normal;
    font-style: normal;

}