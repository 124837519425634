.farm__content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center
}

.farm__content > div:nth-child(2) {
    height:30px;
    position:relative;
    top:50px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

.farm__content-text {
    font-size: 24px;
    font-weight:400;
}

.farm__content-start-farm {
    margin: 0 auto;
    height:50px;
    position:relative;
    top: 70px;
}

.farm__content-start-farm-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 9px;
    color: rgba(88, 43, 2, 1);
    padding: 9px;
    width: 250px;
    background: linear-gradient(
      181deg,
      rgba(254, 208, 131, 1),
      rgba(255, 174, 39, 1)
    );
    box-shadow: 0 3px rgba(0, 0, 0, 0.3), inset 0 -3px rgba(255, 255, 255, 0.1);
    justify-content: center;
    border-radius: 10px;

    transition: transform 0.1s ease-in-out;
  
    &:after {
      content: "";
  
      position: absolute;
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border: 3px solid transparent;
      border-radius: 13px;
      background: linear-gradient(to bottom, #b48d58 0%, #57310e 100%) border-box;
      -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor; /*5'*/
      mask-composite: exclude;
    }

    &:active {
        transform: translateY(1px) scale(0.98);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  
.farm__content > div {
    margin-bottom: 20px;
}

@keyframes appear {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }