.cell {
  background-image: url("./assets/img/cell.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 9.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  width: 50%;
  max-width: 180px;
  gap: 8px;
  outline: none;
}

.cell__text {
  font-size: 24px;
  color: #fdbf4c;
  background: linear-gradient(
    90deg,
    rgba(254, 208, 131, 1),
    rgba(255, 174, 39, 1)
  );
  -webkit-background-clip: text;
  background-clip: text;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar-img {
  width: 42px;
  border-radius: 50%;
}

.coin-img {
  width:30px;
  height:30px;
}



