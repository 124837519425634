.social-card {
    display: flex;
    flex-direction: column;
    gap:5px;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;

    background-image: url("./assets/Wood-texture.png");
    background-size: cover;

    border-radius:13px;
    border: 2px solid #582B02;

    box-shadow: 
    0px -4px 0px 0px #0000001A inset,
    0px 5px 0px 0px #FFFFFF33 inset,
    0px 15px 15px 0px #FFFFFF57 inset,
    0px 3px 0px 0px #00000066;
}

.social-card > img {
    width:30px;
    height: 30px;
}

.social-card > span {
    font-size:11px;
    text-transform: uppercase;
    font-weight:700;
    font-family: 'Riffic',sans-serif;
    color: white;
    text-align: center;
}